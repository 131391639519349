import React, { useState, useCallback } from 'react'
import { detect } from 'detect-browser'

const useNavigateToExtStore = () => {
  let [isExtWarningModalOpen, setExtWarningModalOpen] = useState(false)

  const navigateToStore = useCallback(() => {
    const browser = detect()

    switch (browser && browser.name) {
      case 'chrome':
        window.open(process.env.GATSBY_CHROME_EXTENSION_URL, '_blank')
        break
      // case 'safari':
      //   if (Number(browser.version.split('.')[0]) < 14) {
      //     setExtWarningModalOpen(true)
      //     return
      //   }
      //   window.open(process.env.GATSBY_SAFARI_EXTENSION_URL, '_blank')
      //   break

      default:
        window.open(process.env.GATSBY_CHROME_EXTENSION_URL, '_blank')
    }
  }, [])

  return [isExtWarningModalOpen, setExtWarningModalOpen, navigateToStore]
}

export default useNavigateToExtStore
