import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import CHRHeader from './../components/header'
import CHRFooter from './../components/footer'
import { title } from '../constants'
import { useStyles } from './styles'
import i18n from './../components/i18n'
import { I18nextProvider } from 'react-i18next'
import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import awsConfig from './../aws-exports'
import SEO from './../components/seo'
import { isIOS } from 'react-device-detect'
import CookieBanner from '../components/cookieBanner'
import PullToRefresh from 'react-simple-pull-to-refresh'
// import { useAppState } from '../appState'
import useNavigateToExtStore from '../hooks/useNavigateToExtStore'
import AddChirpyestModal from '../components/joinModales/addChirpyest'

Amplify.configure(awsConfig)
Auth.configure(awsConfig)

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  openSignInModal: boolean
  isJoinPage?: boolean
  hasFooter?: boolean
  withBanner?: boolean
}

const deviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

const Layout = (props: DefaultLayoutProps) => {
  const {
    children,
    openSignInModal,
    isJoinPage,
    hasFooter = true,
    withBanner = false,
  } = props
  const navigateToStore = useNavigateToExtStore()[2]
  const [open, setOpen] = useState(false)
  const classes = useStyles({})
  let isPWAiOS =
    isIOS &&
    (navigator.standalone ||
      window.matchMedia('(display-mode: standalone)').matches)

  const handleRefresh = () => {
    window.location.reload()
  }

  const syncLogout = (event: StorageEvent): void => {
    if (event.key === 'logoutAllTabs' || event.key === 'loggedInRefresh') {
      window.location.reload()
    }
  }

  useEffect(() => {
    window.addEventListener('storage', syncLogout)
    return () => {
      window.removeEventListener('storage', syncLogout)
      window.localStorage.removeItem('logoutAllTabs')
      window.localStorage.removeItem('loggedInRefresh')
    }
  }, [])

  useEffect(() => {
    if (window.localStorage.getItem('addChirpyestModal') === 'true') {
      setTimeout(() => {
        setOpen(true)
        window.localStorage.removeItem('addChirpyestModal')
      }, 60000)
    }
  }, [])

  const pageClasses = classNames({
    [classes.spaceBetween]: hasFooter,
    [classes.flexStart]: !hasFooter,
  })
  return (
    <React.Fragment>
      <SEO
        title={'Shop and Earn cash back'}
        description={
          'Shop 1000+ stores. Share style finds. Make money when you or friends buy from your personal boutique. Fashion, home, beauty, travel, lifestyle.'
        }
      />
      <PullToRefresh
        onRefresh={handleRefresh}
        isPullable={isPWAiOS && !withBanner}
      >
        <div className={pageClasses}>
          <CHRHeader
            openSignInModal={openSignInModal}
            isJoinPage={isJoinPage}
            isScrollable={!hasFooter}
            withBanner={withBanner}
          />
          <I18nextProvider i18n={i18n}>
            <main className={classes.mainContainer}>{children}</main>
          </I18nextProvider>
          {hasFooter && <CHRFooter title={title} />}
        </div>
        <CookieBanner />
        <AddChirpyestModal
          openStatus={
            open &&
            deviceType() === 'mobile' &&
            location.pathname !== '/user/personal-info'
          }
          handleClose={() => setOpen(false)}
          onAddClick={() => {
            navigateToStore()
            setOpen(false)
          }}
        />
      </PullToRefresh>
    </React.Fragment>
  )
}

export default Layout
