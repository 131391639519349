import React, { useCallback, useRef, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { Amplify } from '@aws-amplify/core'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import Dialog from '@material-ui/core/Dialog'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link } from 'gatsby'

import emailIcon from '../../../assets/images/mail.svg'
import googleIcon from '../../../assets/images/google-icon.svg'
import appleIcon from '../../../assets/images/apple.svg'
import burgerMenuIcon from '../../../assets/images/burger-menu.svg'

import { styles } from './styles'
import awsConfig from '../../../aws-exports'
import chrLogo from '../../../assets/images/chirpyest.svg'
import chirpyestTextLogo from '../../../assets/images/chirpyest-text-logo.svg'
import { Box, DialogTitle, IconButton, Typography } from '@material-ui/core'
import useSignIn from '../../../hooks/useSignIn'
import SignInButton from '../signInButton'
import { COLORS } from '../../../constants'
import SignInInput from '../signInInput'
import { checkIfSubmittable } from '../../../../utils/validation'
import CHRHeaderDialog from '../../headerDialog'

// Auth Configs
awsConfig.Auth.oauth.redirectSignOut =
  process.env.GATSBY_OAUTH_REDIRECT_SIGN_OUT_APP
awsConfig.Auth.oauth.redirectSignIn =
  process.env.GATSBY_OAUTH_REDIRECT_SIGN_IN_APP

Amplify.configure(awsConfig)
Auth.configure(awsConfig)

// Types Declarations
interface ISignInProps {
  handleClose: any
  openStatus: boolean
}

const SignInApp = ({ handleClose, openStatus }: ISignInProps) => {
  const classes = styles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [tryCaptcha, setTryCaptcha] = useState(0)
  const [isOverlayOpen, setOverlayStatus] = useState(false)

  const [formErrors, setFormErrors] = useState({
    emailMessage: '',
    passwordMessage: '',
  })

  const reRef = useRef<ReCAPTCHA>(null)

  const {
    isSubmitting,
    requestError,
    handleEmailSignIn,
    handleGoogleSignIn,
    handleAppleSignIn,
    setRequestError,
  } = useSignIn(email, password, setFormErrors, captchaVerified, tryCaptcha)

  const eye = <VisibilityOutlinedIcon className={classes.eyeIcon} />
  const eyeSlash = <VisibilityOffOutlinedIcon className={classes.eyeIcon} />

  const buttonProps = [
    {
      onClick: handleEmailSignIn,
      label: 'sign in with email',
      icon: emailIcon,
      customButtonStyle: { backgroundColor: COLORS.lightTeal },
      customIconStyle: { width: '16px', height: '13.091px' },
      id: 1,
    },
    {
      onClick: handleGoogleSignIn,
      label: 'sign in with google',
      icon: googleIcon,
      customIconStyle: { width: '21.059px', height: '21.586px' },
      id: 2,
    },
    {
      onClick: handleAppleSignIn,
      label: 'sign in with apple',
      icon: appleIcon,
      customIconStyle: { width: '21px', height: '24.419px' },
      id: 3,
    },
  ]

  // Customize buttons order display based on user status
  const signInButtons = [
    buttonProps[0],
    { label: 'or', isText: true },
    ...buttonProps.slice(1),
  ]

  // TODO: Hashem --> fix the onChange function
  const onChange = async (token: any) => {
    // TO REVERT
    if (token === null) {
      reRef?.current?.reset()
      setCaptchaVerified(false)
    } else {
      setCaptchaVerified(true)
      setRequestError('')
    }
    // if (token === null) {
    //   reRef.current.reset()
    //   setCaptchaVerified(false)
    // } else {
    //   setTryCaptcha((preState: number) => (preState += 1))
    //   setRequestError('')
    //   const response = await axios.post(ENDPOINTS.verifyCaptcha, { token })
    //   if (response?.data?.data?.success) {
    //     setCaptchaVerified(true)
    //   } else {
    //     reRef.current.reset()
    //     setRequestError('Captcha token not verified from server!')
    //   }
    // }
  }

  const openMenuOverlay = useCallback(() => {
    setOverlayStatus(true)
  }, [])

  const closeMenuOverlay = useCallback(() => {
    setOverlayStatus(false)
  }, [])

  return (
    <Dialog
      fullScreen={true}
      open={openStatus}
      onClose={handleClose}
      aria-labelledby="signInWithEmail"
      className={classes.dialogContainer}
    >
      {/* Dialog Header */}
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={openMenuOverlay}
          >
            <img
              src={burgerMenuIcon}
              className={classes.menuIcon}
              alt={'menu'}
            />
          </IconButton>

          <img
            src={chirpyestTextLogo}
            className={classes.homeHeaderLogo}
            alt="chirpyest-text-logo"
          />
          <img src={chrLogo} className={classes.logo} />
        </Box>
      </DialogTitle>

      <Box component="section" className={classes.dialogContent}>
        {/* Title & Description */}
        <Typography variant="h2" className={classes.title}>
          sign in
        </Typography>

        {/* User credentials */}
        <Box className={classes.credentials}>
          <Box className={classes.credentialBox}>
            <SignInInput
              value={email}
              onChange={(e: {
                target: { value: React.SetStateAction<string> }
              }) => {
                setEmail(e.target.value)
              }}
              placeholder="email address"
              type="email"
              error={!!formErrors.emailMessage}
              onKeyPress={(event: any) =>
                !isSubmitting &&
                checkIfSubmittable(event, handleEmailSignIn, email, password)
              }
            />
          </Box>
          {formErrors.emailMessage && (
            <p className={classes.errorMessage}>{formErrors.emailMessage}</p>
          )}
          <Box className={classes.credentialBox}>
            <Box className={classes.passwordField}>
              <SignInInput
                value={password}
                onChange={(e: {
                  target: { value: React.SetStateAction<string> }
                }) => {
                  setPassword(e.target.value)
                }}
                placeholder="password"
                type={passwordShown ? 'text' : 'password'}
                error={!!formErrors.passwordMessage}
                onKeyPress={(event: any) =>
                  !isSubmitting &&
                  checkIfSubmittable(event, handleEmailSignIn, email, password)
                }
              />
              <Box
                className={classes.passVisibility}
                onClick={() => setPasswordShown(!passwordShown)}
              >
                {passwordShown ? eye : eyeSlash}
              </Box>
            </Box>
          </Box>
          {formErrors.passwordMessage && (
            <p className={classes.errorMessage}>{formErrors.passwordMessage}</p>
          )}
          <Typography variant="subtitle1" className={classes.forgotPassword}>
            forgot password?{' '}
            <a
              href={`${process.env.GATSBY_SITE_URL}/forgot-password`}
              className={classes.forgotPassword}
              rel="noreferrer"
              target="_blank"
            >
              tap here{' '}
            </a>
          </Typography>
          <Box className={classes.recaptchaWrapper}>
            <Box className={classes.recaptchaContainer}>
              <ReCAPTCHA
                sitekey={`${process.env.GATSBY_RECAPTCHA_SECRET_KEY}`} //"6LexZ3olAAAAAG_dB8FE1zj9wYuq8NmYWI3KqJss" //{`${process.env.GATSBY_RECAPTCHA_SECRET_KEY}`}
                onChange={onChange}
                ref={reRef}
              />
            </Box>
          </Box>
        </Box>

        {/* Sign in Buttons */}
        {requestError && <p className={classes.requestError}>{requestError}</p>}
        <Box>
          {signInButtons.map((button: any) =>
            button?.isText ? (
              <span key={button.id}>{button.label}</span>
            ) : (
              <SignInButton
                key={button.id}
                onClick={button.onClick}
                label={button.label}
                icon={button.icon}
                isSubmitting={isSubmitting}
                customIconStyle={button.customIconStyle}
                customButtonStyle={button.customButtonStyle}
                disabled={isSubmitting}
              />
            )
          )}
        </Box>
        <Typography variant="subtitle1" className={classes.text}>
          no account?{' '}
          <Link
            rel="preconnect"
            to={`/app/join`}
            className={classes.loginButton}
          >
            join here
          </Link>
        </Typography>
      </Box>
      <CHRHeaderDialog
        handleClose={closeMenuOverlay}
        openStatus={isOverlayOpen}
        isFromIos
      />
    </Dialog>
  )
}

export default SignInApp
