import { useState } from 'react'
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { navigate } from 'gatsby'

import { signInValidation } from '../../utils/validation'
import { useAppState } from '../appState'
import axios from '../axios'
import { EXTENSION_ID, ENDPOINTS } from '../constants'

import { sendExtensionMessage } from '../../utils/extensionHelper'
import { TrackingCase } from '../../utils/trackingCases'
import { tracker } from '../systemLogs'

declare const window: any

const useSignIn = (
  email: string,
  password: string,
  setFormErrors: any,
  captchaVerified: boolean,
  tryCaptcha: number
) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [requestError, setRequestError] = useState('')
  // @ts-ignore
  const [, dispatch] = useAppState()

  const isCaptchaVerified = () => {
    if (captchaVerified === false && tryCaptcha == 0) {
      setRequestError('Please verify captcha!')
      return false
    } else if (captchaVerified === false && tryCaptcha > 0) {
      setRequestError('Please verify captcha again!')
      return false
    }
    setRequestError('')

    return captchaVerified
  }

  const handleSuccessfulSignIn = async (user: any) => {
    const {
      data: { data },
    } = await axios.get(
      ENDPOINTS.user.replace(':id', user.attributes['custom:user_id'])
    )

    const currentUserInfo = await Auth.currentCredentials()
    const session = await Auth.currentSession()
    const userProfile = {
      email: data.email,
      userName: data.username,
      fullName: data.name,
      imageKey: data.image,
      imageUrl: '' as Object | string,
      isPro: data.isPro,
      isCreator: data.isCreator,
      isReferralBonus: data.isReferralBonus,
      userDoublePayment: data.userDoublePayment,
      financialData: data.financialData || {
        pending: '0',
        lastClosedOut: '0',
        receivableMilestone: '0',
      },
      loginProvider: data.loginProvider,
    }

    if (data.image && currentUserInfo?.identityId) {
      const url = `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${data.image}`
      userProfile.imageUrl = url
    }

    sendExtensionMessage({ session })
    if (window.chrome?.runtime?.sendMessage) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        { session },
        (_response: any) => {}
      )
    }

    dispatch({ type: 'SIGN_IN', auth: user, userProfile })
    const urlParams = new URLSearchParams(window.location.search)
    const redirectParam = urlParams.get('redirect')

    if (!!redirectParam) {
      navigate(redirectParam + '?fromLogin=true')
      return
    } else {
      navigate('/app/open?fromLogin=true')
      return
    }
  }

  const handleUnauthorizedSignIn = () => {
    setRequestError('not authorized')
    Auth.signOut()
    sendExtensionMessage({ action: 'signOut' })
    if (window.chrome?.runtime?.sendMessage) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        { action: 'signOut' },
        (_response: any) => {}
      )
    }
  }

  const handleSignInError = (error: any) => {
    if (error.errors) {
      if (error.inner[0]?.params.path === 'email') {
        setFormErrors({
          emailMessage: error.inner[0]?.message,
          passwordMessage: error.inner[1]?.message,
        })
      } else {
        setFormErrors({ passwordMessage: error.inner[0]?.message })
      }
      return
    }
    setRequestError('incorrect email or password')
    tracker.track(TrackingCase.UserTracking, `login failed`, {
      errorMessage: error.message,
      email,
      username: email,
    })
  }

  const handleSignIn = async (username: string, password: string) => {
    try {
      setIsSubmitting(true)
      setFormErrors({ emailMessage: '', passwordMessage: '' })

      if (isCaptchaVerified()) {
        await signInValidation({ email, password })
        const user = await Auth.signIn(username, password)

        if (user.signInUserSession.accessToken) {
          await handleSuccessfulSignIn(user)
        } else if (user.signInUserSession.accessToken) {
          handleUnauthorizedSignIn()
        }
      }
    } catch (error) {
      handleSignInError(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleEmailSignIn = () => handleSignIn(email, password)

  const handleGoogleSignIn = () => {
    setIsSubmitting(true)
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    })
      .then(cred => {
        window.Sentry.captureMessage('Google Login Success')
        window.Sentry.captureException(cred)
      })
      .catch(e => {
        console.log(e)
        setIsSubmitting(false)
        window.Sentry.captureMessage('Google Login Error')
        window.Sentry.captureException(e)
      })
  }

  const handleAppleSignIn = () => {
    setIsSubmitting(true)
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Apple,
    }).catch(() => {
      setIsSubmitting(false)
    })
  }

  return {
    isSubmitting,
    requestError,
    handleEmailSignIn,
    handleGoogleSignIn,
    handleAppleSignIn,
    setRequestError,
  }
}

export default useSignIn
