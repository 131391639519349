import React from 'react'
import Dialog from '@material-ui/core/Dialog'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
// import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from '../../../assets/images/close.svg'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import CHRButton from '../../button'
import { Typography } from '@material-ui/core'
import birdIcon from '../../../assets/images/chirpyest-logo.svg'
import LastStepModal from '../../../assets/images/last-step-modal.jpg'
// import { graphql, useStaticQuery } from 'gatsby'

interface AddChirpyestModalProps {
  data: any
  handleClose: any
  openStatus: boolean
  onAddClick: Function
  t: TFunction
}

const AddChirpyestModal = ({
  handleClose,
  openStatus,
  onAddClick,
  t,
}: AddChirpyestModalProps) => {
  const classes = styles()
  // const theme = useTheme()
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  // const { modalImg } = useStaticQuery(query)

  const handleAdding = () => {
    onAddClick()
  }

  return (
    <>
      <Dialog
        open={openStatus}
        onClose={handleClose}
        aria-labelledby="addChirpyestModal"
        className={classes.modal}
        classes={{
          paper: classes.maxHeight,
        }}
      >
        <div className={classes.modalContainer}>
          <button onClick={handleClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
          <Typography variant="h2" className={classes.modalTitle}>
            one last step
          </Typography>
          <Typography variant="subtitle1">
            add the chirpyest browser extention. it will automatically scan
            hundreds of affiliated retailer sites to find cash back when you are
            shopping anywhere on the web!
          </Typography>
          <img
            className={classes.modalImage}
            src={LastStepModal}
            alt="add chirpyest extension"
          />
          {/* <Img
            fluid={modalImg.childImageSharp.fluid}
            alt="add chirpyest"
            className={classes.modalImage}
          /> */}
          <CHRButton
            onClick={handleAdding}
            label="add chirpyest - it's free"
            icon={birdIcon}
            customStyle={classes.buttonStyles}
          />
        </div>
      </Dialog>
    </>
  )
}

export default withTranslation()(AddChirpyestModal)

// const query = graphql`
//   query {
//     modalImg: file(relativePath: { eq: "last-step-modal.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1000) {
//           ...GatsbyImageSharpFluid_noBase64
//         }
//       }
//     }
//   }
// `
